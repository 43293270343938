import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stylesHome: {},
    };

    this.homeTitle = null;
    this.homeDescription = null;
    this.homeDescriptionAccent = null;
    this.homeCTA = null;

    this.timelineLoad = null;
    this.hasSoughtEnd = false;

    this.refWordsPerson = null;
    this.refWordsEmail = null;
    this.refWordsCredit = null;
    this.refWordsPhone = null;
    this.refWordsTwitter = null;
    this.refWordsTitle = null;

    this.speedWordsPerson = 0;
    this.speedWordsCredit = 0.07;
    this.speedWordsEmail = 0.25;
    this.speedWordsPhone = 0.3;
    this.speedWordsTwitter = 0.05;
    this.speedWordsTitle = 0.1;

    this.refTitleName = null;
    this.refDescription = null;
    this.refCTA = null;

    this.speedTitleName = 0.2;
    this.speedDescription = 0.04;
    this.speedCTA = 0.07;

    // this.onScrollHandler = _.throttle(this.onScroll, 150);
    this.onScrollHandler = _.throttle(this.onScroll, 75);
    // this.onScrollHandler = _.throttle(this.onScroll, 300);
  }

  static contextTypes = Constants.contextTypesTransitionOnly;

  componentDidMount() {
    this.timelineLoad = new TimelineMax({ paused: true })
      .to({}, 1.02, {})
      .add(() => TweenMax.to(this.homeTitlePreload, 0.8, { ease: 'Mo', opacity: 0.5 }))
      .to({}, 0.2, {})
      .add(() => this.homeDescription.play())
      // .to({}, 0.5, {})
      .to({}, 0.6, {})
      .add(() => this.homeDescriptionAccent.play())
      // .to({}, 0.1, {})
      .to({}, 0.2, {})
      .add(() => this.homeCTA.play())
      .to({}, 0.1, {})
      .add(() => TweenMax.to(this.homeWords, 0.8, { ease: 'Mo', opacity: 1 }))
      .to({}, 0.1, {})
      .add(() => TweenMax.to(this.homeTitle, 0.8, { ease: 'Mo', opacity: 1 }));

    this.loadHome();

    window.addEventListener('scroll', this.onScrollHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollHandler);
  }

  onScroll = () => {
    let scrollPosition = Calculate.getScrollPosition();
    let progress = scrollPosition / window.innerWidth;

    let updateTiming = 0.25;

    // console.log('SCROLL PROGRESS', progress);

    if (progress < 6) {
      if (this.refWordsPerson)
        TweenMax.to(this.refWordsPerson, updateTiming, { x: scrollPosition * this.speedWordsPerson });
      if (this.refWordsEmail)
        TweenMax.to(this.refWordsEmail, updateTiming, { x: scrollPosition * this.speedWordsEmail });
      if (this.refWordsCredit)
        TweenMax.to(this.refWordsCredit, updateTiming, { x: scrollPosition * this.speedWordsCredit });
      if (this.refWordsPhone)
        TweenMax.to(this.refWordsPhone, updateTiming, { x: scrollPosition * this.speedWordsPhone });
    }

    if (progress < 1.2) {
      if (this.refWordsTwitter)
        TweenMax.to(this.refWordsTwitter, updateTiming, { x: scrollPosition * this.speedWordsTwitter });
      if (this.refWordsTitle)
        TweenMax.to(this.refWordsTitle, updateTiming, { x: scrollPosition * this.speedWordsTitle });

      if (this.refTitleName)
        TweenMax.to(this.refTitleName, updateTiming, {
          backgroundPosition: `${100 - progress * 100 * this.speedTitleName}% 0%`,
        });

      if (this.refDescription)
        TweenMax.to(this.refDescription, updateTiming, { x: scrollPosition * this.speedDescription });

      if (this.refCTA) TweenMax.to(this.refCTA, updateTiming, { x: scrollPosition * this.speedCTA });
    }

    // if (this.refWordsPerson)
    //   TweenMax.set(this.refWordsPerson, { ease: 'Mo', x: scrollPosition * this.speedWordsPerson });
    // if (this.refWordsEmail)
    //   TweenMax.set(this.refWordsEmail, { ease: 'Mo', x: scrollPosition * this.speedWordsEmail });
    // if (this.refWordsCredit)
    //   TweenMax.set(this.refWordsCredit, { ease: 'Mo', x: scrollPosition * this.speedWordsCredit });
    // if (this.refWordsPhone)
    //   TweenMax.set(this.refWordsPhone, { ease: 'Mo', x: scrollPosition * this.speedWordsPhone });
    // if (this.refWordsTwitter)
    //   TweenMax.set(this.refWordsTwitter, { ease: 'Mo', x: scrollPosition * this.speedWordsTwitter });

    // console.log('progress', progress);
    // if (this.timelineWords && this.refWordsPerson)
    //   this.timelineWords.pause(scrollPosition / window.innerWidth);
  };

  loadHome = () => {
    if (this.props.location.pathname == '/') {
      this.timelineLoad.play();
      this.hasSoughtEnd = true;
    }
  };

  seekToEnd = () => {
    if (!this.hasSoughtEnd) {
      this.timelineSeekToEnd = new TimelineMax()
        .add(() => this.timelineLoad.restart().progress(1))
        .add(() => this.homeDescription.seekToEnd())
        .add(() => this.homeDescriptionAccent.seekToEnd())
        .add(() => this.homeCTA.seekToEnd());
    }

    this.hasSoughtEnd = true;
  };

  updateProjectsOpacity = (value, time) => {
    TweenMax.to(this.home, time, { ease: 'Mo', opacity: value });
    TweenMax.to(this.homeWords, time, { ease: 'Mo', opacity: value });
  };

  scrollToProject = () => {
    let scrollDestination = 0;
    let oneRem = Calculate.getOneRem();
    let offsetLeft = window.innerWidth / 10 - (oneRem * 96) / 16;
    let marginRight = window.innerWidth - oneRem * 76.25 + oneRem * 8;
    scrollDestination = Calculate.getIdealProjectScrollPosition(0);

    if (isNaN(scrollDestination) || scrollDestination <= 0 || scrollDestination == null) {
      scrollDestination = window.innerWidth + marginRight + offsetLeft;
    }

    // if (this.homeTitle) {
    //   // let homeBoundingBox = this.homeTitle.getBoundingClientRect();
    //   // let scrollPosition = Calculate.getScrollPosition();
    //   // scrollDestination = homeBoundingBox.x + homeBoundingBox.width + scrollPosition;
    //   // console.log('homeX', homeBoundingBox.x);
    //   // console.log('homeCombo', homeBoundingBox.x + homeBoundingBox.width);
    //   // console.log('scrollPosition', scrollPosition);
    // } else {
    //   //calc(100vw - 76.25rem + 8rem)
    //   let scrollDestination = window.innerWidth + marginRight + offsetLeft;
    // }
    // let scrollDestination = window.innerWidth + marginRight;
    // let scrollDestination = window.innerWidth;

    // console.log('marginRight', marginRight);

    TweenMax.to(window, 0.8, {
      ease: 'Mo',
      scrollTo: {
        y: 0,
        x: scrollDestination,
      },
    });
  };

  remove = () => {
    this.setState({ stylesHome: { display: 'none' } });
  };

  onEnterHome = (node) => {
    if (node) {
      this.setState({ stylesHome: {} });
    }
  };

  onCTAMouseEnter = () => {
    // console.log('CTA Mouse Enter');
    let oneRem = Calculate.getOneRem();
    TweenMax.to(this.refCTABar, 0.4, { ease: 'Mo', x: (-87 / 16) * oneRem, scaleX: 3.1071428571 });
  };

  onCTAMouseLeave = () => {
    // console.log('CTA Mouse Leave');
    TweenMax.to(this.refCTABar, 0.4, { ease: 'Mo', x: 0, scaleX: 1 });
  };

  onExitedHome = (node) => {
    if (node) {
      this.remove();
    }
  };

  RouteHome = () => (
    <section className="home" ref={(section) => (this.home = section)} style={this.state.stylesHome}>
      <div className="home__content">
        <div className="home__content__container">
          <div className="home__title">
            <h1 className="home__title__content--preload" ref={(h1) => (this.homeTitlePreload = h1)}>
              Hi, I&rsquo;m Jay Mo and this is my work.
            </h1>
            <h1 className="home__title__content" ref={(h1) => (this.homeTitle = h1)}>
              Hi, I&rsquo;m{' '}
              <span ref={(span) => (this.refTitleName = span)} className="home__title__content__mask">
                Jay Mo
              </span>{' '}
              and this is my work.
            </h1>
          </div>
          <div ref={(div) => (this.refDescription = div)} className="home__description">
            <SplitParagraph
              className="home__description__content"
              ref={(el) => (this.homeDescription = el)}
              identifier="hd-1"
            >
              To my family and many around me, I'm an incomprehensible 19 year old. They question why I'm
              never satisfied with the status quo and why I live like I'm always running out of time. But as
              an execution-focused product designer, with pride in craftsmanship and strengths in front-end
              development, I love building well-detailed products for people and I understand the value of it.
              Now is a pivotal life moment, and I need guidance.
            </SplitParagraph>
            <p className="home__description__content--accent">
              <AnimateText ref={(el) => (this.homeDescriptionAccent = el)}>
                That's why I dream of and want to work for you so badly.
              </AnimateText>
            </p>
          </div>
          <div className="home__cta">
            <p
              ref={(p) => (this.refCTA = p)}
              className="home__cta__content"
              onClick={this.scrollToProject}
              onMouseEnter={this.onCTAMouseEnter}
              onMouseLeave={this.onCTAMouseLeave}
            >
              <AnimateText ref={(el) => (this.homeCTA = el)}>
                View work
                <span ref={(span) => (this.refCTABar = span)} className="home__cta__content__arrow" />
              </AnimateText>
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  RouteWords = () => (
    <section className="words" ref={(section) => (this.homeWords = section)} style={this.state.stylesHome}>
      <div className="words__column--1">
        <h4 className="words__column__content">born @ seoul, now @ nyc</h4>
      </div>
      <div className="words__grid">
        <div className="words__column--2">
          <h4 ref={(h4) => (this.refWordsPerson = h4)} className="words__column__content--top">
            to {Constants.person.name.split(' ')[0].toLowerCase()} at {Constants.person.company.toLowerCase()}
          </h4>
          <h4 ref={(h4) => (this.refWordsEmail = h4)} className="words__column__content--bottom">
            <a href="mailto:jay@jaymo.io" target="_blank">
              jay@jaymo.io
            </a>
          </h4>
        </div>
        <div className="words__column--3">
          <h4 ref={(h4) => (this.refWordsCredit = h4)} className="words__column__content--top">
            <div>designed and developed</div>
            <div>by me</div>
          </h4>
          <h4 ref={(h4) => (this.refWordsPhone = h4)} className="words__column__content--bottom">
            <a href="sms:+12019251066" target="_blank">
              +1 201 925 1066
            </a>
          </h4>
        </div>
        <div className="words__column--4" />
        <div className="words__column--5" />
        <div className="words__column--6">
          <h3 ref={(h3) => (this.refWordsTitle = h3)} className="words__column__content--top">
            portfolio 2020
          </h3>
          <h4 ref={(h4) => (this.refWordsTwitter = h4)} className="words__column__content--bottom">
            <a href="https://twitter.com/jayhxmo" target="_blank">
              @jayhxmo
            </a>
          </h4>
        </div>
      </div>
      <div className="words__column--7" />
    </section>
  );

  RouteHomeNull = () => <div className="home-tracker" />;

  render() {
    const { RouteHome, RouteWords } = this;

    return (
      <TransitionGroup component={null}>
        <Route path="/" component={this.RouteHome} location={this.props.location} />
        <Route path="/" component={this.RouteWords} location={this.props.location} />
        <Transition
          appear
          key={'#home-' + (this.props.location.pathname == '/')}
          onEnter={(node) => {
            this.onEnterHome(node);
          }}
          onExited={(node) => {
            this.onExitedHome(node);
          }}
          timeout={1300}
        >
          <Route exact path="/" component={this.RouteHomeNull} location={this.props.location} />
        </Transition>
      </TransitionGroup>
    );
  }
}
